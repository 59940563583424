/* eslint-disable no-undef */
// import "@justinribeiro/lite-youtube"
import "lite-youtube-embed/src/lite-yt-embed.css"
import "lite-youtube-embed/src/lite-yt-embed.js"
// import "lite-youtube-embed"

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`
  // )

  // if (answer === true) {
  // }
  window.location.reload()
}
